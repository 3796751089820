import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let minutes = value % 60;
    let hours = (value - minutes) / 60;
    // let stringMinutes = minutes.toString().length == 1 ? '0' + minutes.toString() : minutes.toString();
    let stringRender = hours + 'h ' + minutes + 'm';
    return stringRender;
  }

}
