import { Component, OnInit, NgModule, ViewChild, ViewContainerRef } from '@angular/core';
import { RightBarService } from "../../shared/services/right-bar-service";
import { Observable } from 'rxjs';
import { Router } from "@angular/router";

@Component({
  selector: 'app-right-bar',
  templateUrl: './right-bar.component.html',
  styleUrls: ['./right-bar.component.scss']
})
export class RightBarComponent implements OnInit {

  @ViewChild('dynamic', {
    read: ViewContainerRef
  }) viewContainerRef: ViewContainerRef;

  title: Observable<any[]>;
  direccion: Observable<any[]>;
  exit: Observable<any>;
  overlay: Observable<any>;
  constructor(
    private _rightBarService: RightBarService,
    private _router: Router
  ) {
    this.title = this._rightBarService.getTitle();
    this.direccion = this._rightBarService.getDireccion();
    this.exit = this._rightBarService.getStatuaBar();
    this.overlay = this._rightBarService.getOverlay();
    this._router.events.subscribe((event) => {
      this.notification_close();
    });
  }

  ngOnInit() {
    this._rightBarService.setRootViewContainerRef(this.viewContainerRef);
  }

  notification_close() {
    this._rightBarService.closeRightBar();
  }
}
