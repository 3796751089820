import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'vuelosStep2'
})
export class VuelosFilterPipe implements PipeTransform {
    transform(items: any, filter): any {
        console.log("filtro", filter)
        console.log("filtrando", items);
        let res = items
        // let items2 = Object.assign(Object.create(Object.getPrototypeOf(items)), items);
        if (filter != undefined && filter != null) {
            if (filter.precio.length > 0) {
                res = items.filter(vuelos => filter.precio.includes(vuelos.price.total));
            }
            if (filter.estudiante) {
                res = items.filter(vuelos => vuelos.fareType === "STU" || vuelos.fareType === " STU01");
            }
            res = res.map(x => {
                x.segments = x.segments.map((segment) => {
                    //filtro escalas
                    if (filter.escalas.length > 0) {
                        segment.routes = segment.routes.filter(route => filter.escalas.includes(route.subRoutes.length));
                    }
                    if (!filter.aerolineas.includes('ALL') && filter.aerolineas.length > 0) {

                        segment.routes = segment.routes.map(route => {
                            route.subRoutes = route.subRoutes.filter(subRoute => filter.aerolineas.includes(subRoute.airline))

                            return route;
                        });

                    }

                    if (filter.clase.length > 0) {
                        segment.routes = segment.routes.map(route => {
                            route.subRoutes = route.subRoutes.filter(subRoute => filter.clase.includes(subRoute.bookingClass))
                            return route;
                        });
                    }

                    segment.routes = segment.routes.filter(route => route.subRoutes.length > 0);

                    return segment;
                })
                return x;
            });
        }
        console.log("respuesta del filtro", res);
        return res;
    }
}
