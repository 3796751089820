import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DetallesService {
  private dataFormSubject = new BehaviorSubject([]);
  private dataForm;
  constructor() { }

  getDataForm() {
    return this.dataFormSubject.asObservable();
  }

  setDataForm(data) {
    this.dataForm = data;
    this.refresh();
  }

  cleanData() {
    this.dataForm = null;
    this.refresh();
  }

  private refresh() {
    this.dataFormSubject.next(this.dataForm);
  }
}
