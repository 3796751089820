import { Injectable } from '@angular/core'
import { MethodsService } from "../../method.service";
import { BehaviorSubject } from 'rxjs';
import { reject } from 'q';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CotizacionService {

  private cotizacionSubject = new BehaviorSubject([]);
  private cotizacion;
  private deleteOptionsSubject = new BehaviorSubject([]);
  private deleteOptions = null;

  constructor(
    private _service: MethodsService
  ) {

  }

  setCotizacion(cotizacion) {
    this.cotizacion = cotizacion;
    this.refresh();
  }

  getCotizacion() {
    return this.cotizacionSubject.asObservable();
  }

  private refresh() {
    localStorage.setItem('cotizacion', JSON.stringify(this.cotizacion));
    this.cotizacionSubject.next(this.cotizacion);
  }

  /**
   * @description Crear nueva cotización
   *
   * @param {string} email - Email del cliente a crear cotización
   */
  newQuote(client?) {

    let email = JSON.parse(localStorage.getItem('cotizacion-cliente')).email;
    let quoteNumber = "";
    try {
      quoteNumber = JSON.parse(localStorage.getItem('cotizacion')).number;
    } catch (error) {
      quoteNumber = "";
    }
    let contactFormClient = JSON.parse(localStorage.getItem('contactFormSelected'));
    let formAdvertising = JSON.parse(localStorage.getItem('advertisingSelected'));

    let bodyForm = {
      client: {
        email: email
      },
      contactFormClient: contactFormClient,
      formAdvertising: formAdvertising
    };

    return this._service.post("quote/newQuote?numberQuote=" + quoteNumber, bodyForm)
      .map((data) => {
        this.setCotizacion(data.data);
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }


  /**
   * @description Obtener cotización por número de cotización
   *
   * @param {(object|string)} cotization - Objeto JSON de la cotización o número
   */
  getCotizationByNumber(cotization) {
    let number = null;
    if ((typeof cotization === 'string') || (typeof cotization === 'number')) {
      number = cotization;
    } else {
      //Se obtiene el número de cotización
      number = cotization.number;
    }


    return this._service.post('quote/findByNumber/' + number, '')
      .map((data) => {
        this.setCotizacion(data.data);
        return data.data;
      })
      .catch((err) => {
        return err;
      });

  }

  /**
   * @description Agregar producto seleccionado a la lista
   *
   * @param {object} cotization - Objeto JSON de la cotización
   */
  addProductList(cotization) {

    //Se obtiene el número de cotización
    let number = cotization.number;

    return this._service.post("quote/addProductList/" + number, '')
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }

  /**
 * Agregar producto seleccionado a la lista de membresias
 *
 * @param {object} cotization - Objeto JSON de la cotización
 */
  addListProductList(cotization) {

    //Se obtiene el número de cotización
    let number = cotization.number;

    return this._service.post("quote/addListProductList/" + number, '')
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }

  /**
   * @description Agregar producto seleccionado a la lista
   *
   * @param {object} cotization - Objeto JSON de pasajeros
   */
  addPassengerProduct(cotization, client) {
    //Cambiar formato de birthday
    cotization.passengerDataList.map(p => { p.birthday = p.birthday.year + "-" + p.birthday.month + "-" + p.birthday.day; p.birthday = moment(p.birthday).format('YYYY-MM-DD'); });
    cotization.passengerDataList.map(p => { p.mail = client.email; });
    cotization.passengerDataList.map(p => { p.phone = client.mobile; });

    return this._service.post("quote/addPassengerProduct", cotization)
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }

  /**
   * @description Añade locatorCode del PNR generado
   * @param {number} number - Número de cotización
   * @param {string} index - Indice de producto
   * @param {string} pnr - PNR generado
   * @returns TODO:
   */
  setLocatorCode(number, index, pnr) {

    let locator = {
      "locatorList": [
        pnr
      ]
    };

    return this._service.post(`quote/${number}/product/${index}/locator`, locator)
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }

  /**
     * @description Actualizar nota
     * @param {number} number - Número de cotización
     * @param {string} note - Texto de nota que se actualizará
     * @returns Nota actualizada
     */
  updateNote(number, note) {
    return this._service.putMethod(`quote/${number}/notes`, note)
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }

  /**
   * @description Cambiar de paso
   * @param {number} number - Número de cotización
   * @param {number} step - Paso a cambiar
   * @returns Objeto cotización con el paso actualizado y operación correspondiente (BACK-END)
   */
  changeStep(number, step) {
    return this._service.putMethod(`quote/${number}/changeStep?step=${step}`, '')
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }

  updateClient(client, numQuote) {
    return this._service.putMethod("client/quote/" + numQuote, client);
  }

  cancelarEmision(numQuute, indexProduct) {
    let body = {
      generateArticleIntelisis: true,
      indexProduct: indexProduct,
      numberQuote: numQuute
    }
    return this._service.post("issueProduct/cancelarEmision", body);
  }

  /**
     * @description Obtener lista de terminales
     * @returns Arreglo de objetos de terminales
     */
  getTerminals() {
    return this._service.get('terminal', '')
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }

  /**
   * @description Obtener lista de tipos de tarjeta
   * @returns Arreglo de objetos de tipos de tarjeta
   */
  getCards() {
    return this._service.get('typeCards', '')
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }

  /**
   * @description Obtener lista de bancos
   * @returns Arreglo de objetos de bancos
   */
  getBanks() {
    return this._service.get('banks', '')
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }


  deleteProduct(numberQuote, productIndex, body) {
    return this._service.putMethod("quote/" + numberQuote + "/product/" + productIndex + "/deleted/", body);
  }

  getDeleteOptions() {
    if (!this.deleteOptions) {
      this._service.get("deleteProductReason/list", "").subscribe(data => {
        this.deleteOptions = data.data;
        this.deleteOptionsSubject.next(this.deleteOptions);
      }, err => {

      });
    }
    return this.deleteOptionsSubject.asObservable();
  }

  recoverProduct(numberQuote, productIndex) {
    return this._service.putMethod("quote/" + numberQuote + "/product/" + productIndex + "/recover/", "");
  }

}
