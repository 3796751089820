import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';


@Injectable({
  providedIn: 'root'
})
export class Socket1Service {

  private serverUrl = 'http://10.20.30.106:8023/fuv_core_pnr_socket/pnr'
  private title = 'WebSockets chat';
  private stompClient;

  constructor() { 
    this.initializeWebSocketConnection();
  }

  initializeWebSocketConnection(){
    console.log("Inicializado Socket 1");
    let ws = new SockJS(this.serverUrl);
    this.stompClient = Stomp.over(ws);
    this.stompClient.debug = null //Esta línea quita los console.log de STOMP.JS
    let that = this;
    this.stompClient.connect({}, function(frame) {
      that.stompClient.subscribe("/topic/pnr_notification", (message) => {
        if(message.body) {
          console.log("Mensaje 1:", message.body);
        }
      });
    });
  }

  sendMessage(message){
    this.stompClient.send("/app/send/message" , {}, message);
  }


}
