import { Injectable } from '@angular/core';
import { MethodsService } from '../method.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private _service: MethodsService
  ) { }

  getGraficas() {
    return this._service.get('dashboard/getGraficas', '')
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }

  getCotizaciones() {
    return this._service.get('dashboard/getCotizaciones', '')
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }

  /**
   * Función para buscar de manera general en base a los criterios de busqueda
   * @param datosBusqueda
   */
  searh(datosBusqueda: any) {
    return this._service.get('dashboard/getSpotlightQuote/' + datosBusqueda, '')
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }
}
