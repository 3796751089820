import { ComponentFactoryResolver, Injectable, Inject } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MethodsService } from '../../method.service';
import { reject } from 'q';

@Injectable()
export class FacturarService {
    private advancesSubject = new BehaviorSubject([]);
    private advances;

    private balanceGlobalSubject = new BehaviorSubject([]);
    private balanceGlobal;
    private balanceFiltered;

    constructor(
        private _service: MethodsService
    ) {

    }

    searchBalance(client) {
        if (client != null) {
            let body = {
                "email": client.email,
                "lastname": client.lastname,
                "name": client.firstname,
                "order": "",
                "type": ""
            };
            return this._service.post("intelisis/dataClient", body).map(data => {
                console.log("informacion en balance:", data.data);
                this.balanceGlobal = JSON.parse(JSON.stringify(data.data));
                this.balanceFiltered = data.data;
                this.refresh();
                this.checkAdvanceBalance();
                return data;
            }, err => {
                this.balanceGlobal = null;
                this.balanceFiltered = null;
                this.refresh();
                return err;
            });
        }
        reject("Cliente no puede ser null");
    }

    setAdvances(value) {
        this.advances = value;
        console.log("advances", value);
        this.advancesSubject.next(this.advances);
        this.checkAdvanceBalance();
    }

    getBalance() {
        return this.balanceGlobalSubject.asObservable();
    }

    getAdvances() {
        return this.advancesSubject.asObservable();
    }

    private refresh() {
        console.log(this.advances);
        this.advancesSubject.next(this.advances);
        this.balanceGlobalSubject.next(this.balanceFiltered);
    }

    balanceMovementToAdvances(movement, clientId) {
        this.advances.push(this.balanceFiltered.clients.filter(client => client.rfc === clientId)[0].movements.filter(x => x == movement)[0]);
        this.balanceFiltered.clients = this.balanceFiltered.clients.map(client => {
            if (client.rfc === clientId) {
                client.movements = client.movements.filter(x => x !== movement);
            }
            return client;
        });
        this.refresh();
    }

    advancesToBalance(advance) {
        if (advance.id != null && advance.id != undefined) {
            this.balanceFiltered.clients[0].movements.push(this.advances.filter(x => x === advance)[0]);
        } else {
            let movement = this.balanceGlobal.clients[0].movements.filter(movement => movement.id == advance.idOrder.split("|")[1])[0];
            this.balanceFiltered.clients[0].movements.push(movement);
        }
        this.advances = this.advances.filter(x => x !== advance);
        this.refresh();
    }

    checkAdvanceBalance() {
        if (this.advances != null && this.advances != undefined && this.balanceFiltered != null && this.balanceFiltered != undefined) {
            this.balanceFiltered.clients = this.balanceFiltered.clients.map(client => {
                client.movements = client.movements.filter(movement => this.advances.filter(advance => movement.id == advance.idOrder.split("|")[1]).length == 0);
                return client;
            });
        }
    }

    facturar(intelisis): Observable<Object> {
        let body = {
            advances: "",
            orders: "",
            tpPayment: "",
        }
        this.advances.forEach((advance, i) => {
            if (advance.idOrder != null && advance.idOrder != undefined) {
                body.advances += advance.idOrder.split('|')[1];
                body.tpPayment += advance.tpPayment;
            } else {
                body.advances += advance.id;
                body.tpPayment += advance.mov == "Anticipo Clientes" ? "Efectivo" : advance.mov;
            }
            if (!(this.advances.length - 1 == i)) {
                body.advances += ",";
                body.tpPayment += ",";
            }
        })

        body.orders = intelisis.order.idOrder.split("|")[1];
        return this._service.post("intelisis/receipt", body);
    }

    addArticles(articles) {
        return this._service.post("intelisis/article", articles);
    }

    payInvoice(body, numberQuote) {
        return this._service.post('payment/order/' + numberQuote + '/invoice', body);
    }

}