import { Injectable } from '@angular/core'
import { MethodsService } from "../method.service";

@Injectable()
export class SeguimientosService {

    filter: any = {
        endDate: "",
        startDate: "",
        branche: "",
        username: "",
        statusNotEqual: ["CERRADA_GANADA", "CERRADA_PERDIDA"]
    };

    constructor(
        private _service: MethodsService
    ) {

    }

    search() {
        let d = new Date();
        d.setMonth(d.getMonth() - 1);
        this.filter.startDate = this.formatDate(d);
        d.setMonth(d.getMonth() + 3);
        this.filter.endDate = this.formatDate(d);

        return this._service.post('tracing/findByTrackingDateBetween', this.filter)
            .map((data) => {
                return data.data;
            })
            .catch((err) => {
                return err;
            });
    }

    formatDate(date) {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        return this.pad(day) + '/' + this.pad(month) + '/' + year;
    }

    pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

}

