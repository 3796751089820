import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer
export const FULL_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: './pages/full-layout-page/dashboard/dashboard.module#DashboardModule'
    },
    {
        path: 'seguimientos',
        loadChildren: './pages/full-layout-page/seguimientos/seguimientos.module#SeguimientosModule'
    },
    {
        path: 'clientes',
        loadChildren: './pages/full-layout-page/clientes/clientes.module#ClientesModule'
    },
    {
        path: 'cotizacion',
        loadChildren: './pages/full-layout-page/cotizacion/cotizacion.module#CotizacionModule'
    },
    {
        path: 'usuarios',
        loadChildren: './pages/full-layout-page/usuarios/usuarios.module#UsuariosModule'
    },
    {
        path: 'busqueda',
        loadChildren: './pages/full-layout-page/busqueda/busqueda.module#BusquedaModule'
    }

];
