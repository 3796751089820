import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment as params } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public sessionOut: boolean = false;
  title = 'app';
  production = (<any>params).production;
  constructor(
    private translate: TranslateService
  ) {
    this.detectLang();
    var _log = console.log;

    // window.console.log = function (logMessage) {
    //   if (!(<any>params).production) {
    //     _log.apply(console, arguments);
    //   }
    // };
  }

  private detectLang() {
    const userLang = navigator.language.split('-')[0] || 'en';
    this.translate.setDefaultLang(userLang);
    this.translate.use(userLang);
  }
}
