import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pags',
  templateUrl: './pags.component.html',
  styleUrls: ['./pags.component.scss']
})
export class PagsComponent implements OnInit, OnChanges {
  numbersLength = 5;

  public page: any;
  public sizePage: any;

  @Input() data = [];
  @Input() firstPage: number = 1;
  @Output() pages: EventEmitter<any> = new EventEmitter<any>();
  @Input() pageSize: any;

  constructor() {
  }

  ngOnInit() {
    this.page = this.firstPage;
    this.sizePage = this.data?Math.ceil(this.data.length / this.pageSize):0;
    console.log("SIZE:", this.sizePage);
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.page = this.firstPage;
    this.sizePage = this.data?Math.ceil(this.data.length / this.pageSize):0;
  }

  changePage(type) {
    console.log("Cambiar:", type);
    switch (type) {
      case 'prev':
        this.page--;
        this.pages.emit(this.page);
        break;
      case 'next':
        this.page++;
        this.pages.emit(this.page);
        break;
      default:
        this.page = Number(type);
        this.pages.emit(this.page);
        break;
    }
  }

}
