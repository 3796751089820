import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'seguimientos'
})
export class SeguimientosPipe implements PipeTransform {

    transform(items: any, filter: any): any {
        let exp = new RegExp(filter, 'i');
        return items.filter(item => exp.test(item.number) || exp.test(item.client.fullname) || exp.test(item.client.id_intelisis) 
            || exp.test(item.client.email) || exp.test(item.client.mobile));
    }
}
