import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../../_auth/auth.service";
import { BREAKPOINT } from '@angular/flex-layout';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
  intelisis: any = {
    USDExchangeRate: 0,
    EURExchangeRate: 0
  };
  sabre: any = {
    usdRate: 0,
    eurRate: 0
  };
  constructor(
    private _router: Router,
    private _auth: AuthService
  ) { 
    this.getExchange();
  } 

  ngOnInit() {
  }

  getTitle() {
    switch (this._router.url) {
      case '/dashboard':
        return 'Mi Dashboard';
      case '/clientes':
        return 'Mis Clientes';
        case '/clientes/nuevo':
          return 'Mis Clientes | Nuevo Cliente';
      case '/seguimientos':
        return 'Estatus de mis cotizaciones';
      default:
        return '';
    }
  }

  getExchange() {
    let user = this._auth.getUser();
    if(user.CURRENCY_SABRE) {
      user.CURRENCY_SABRE.map((data) => {
        switch(data.currency) {
          case "USD":
            this.sabre.usdRate = data.rate;
            break;
          case "EUR":
            this.sabre.eurRate = data.rate;
            break;
        }
      });
    }
    if(user.CURRENCY_INTELISIS) {
      this.intelisis.USDExchangeRate = user.CURRENCY_INTELISIS.USDExchangeRate;
      this.intelisis.EURExchangeRate = user.CURRENCY_INTELISIS.EURExchangeRate;
    }
  }

}
