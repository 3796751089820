'use strict';

import { Carrier } from './carrier';
import { Roll } from './roll';
import { Access } from './access';
import { Address } from './address';

export class User {
	id?: number;
	nombre_Usuario: string;
	apellido_Paterno: string;
	apellido_Materno: string;
	nombre: string;
	telefono: string;
	email: string;
	status?: boolean;
	activo?: boolean;
	carrier?: Carrier;
	primer_Login?: boolean;
	password?: string;
	rol?: Roll;
	accesos?: Access[];
	selected?: boolean;
	isNew?: boolean;

	constructor() {
		this.id = null;
		this.nombre_Usuario = null;
		this.apellido_Paterno = null;
		this.apellido_Materno = null;
		this.nombre = null;
		this.telefono = null;
		this.email = null;
		this.carrier = { id: null, nombre: null };
	}

}
