import { Component, OnInit } from '@angular/core';
import { DetallesService } from '../../../../shared/services/detalles.service';
import { UtilitiesAppService } from '../../../../shared/services/utilities-app.service';

@Component({
  selector: 'app-detalles',
  templateUrl: './detalles.component.html',
  styleUrls: ['./detalles.component.scss', '../../../../../assets/fuv/css/style-rightbar-details.css']
})
export class DetallesComponent implements OnInit {
  tracking: any;
  constructor(
    private _detallesService: DetallesService,
    private _util: UtilitiesAppService
  ) {
    this.getDataForm();
  }

  ngOnInit() {
  }


  getDataForm() {
    this._detallesService.getDataForm().subscribe((data: any) => {
      this.tracking = Object.assign(Object.create(Object.getPrototypeOf(data)), data);
    }, (err) => {

    });
  }

  formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return this.pad(day) + '/' + this.pad(month) + '/' + year;
  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  searchQuote() {
    // console.log('valor del formulario', this.tracking);
    this._util.cotizacion.searchQuote(this.tracking.number);
  }
}
