import { Component, OnInit, Input } from '@angular/core';
import * as $ from "jquery";
import { CotizacionService } from 'src/app/shared/http/full-layout-page/cotizacion/cotizacion.service';

@Component({
  selector: 'app-mis-notas',
  templateUrl: './mis-notas.component.html',
  styleUrls: ['./mis-notas.component.scss']
})
export class MisNotasComponent implements OnInit {
  @Input() cotizacion:any;
  notas: string;
  editable:boolean = false;
  constructor(
    private _cotizacionService: CotizacionService
  ) { 
  }



  ngOnInit() {
    console.log("COT:", this.cotizacion)
    // this.notas = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vitae quam bibendum, lobortis magna nec, laoreet nibh. Vestibulum nec tempor purus. Praesent eu dapibus ex. Vestibulum sit amet finibus sapien, a pulvinar lacus. Etiam iaculis consequat nisl eget molestie. Etiam sollicitudin massa rhoncus faucibus convallis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nam id felis turpis. Duis molestie magna eu ligula vulputate molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit.";
    this.notas = this.cotizacion?this.cotizacion.notes:'';
  }

  verMas() {
    this.editable = true;
    $('#noteComp').show();
    $('#noteSlice').hide();
    $('#btnMostrar').hide();
    $('#btnOcultar').show();
  }

  verMenos() {
    this.editable = false;
    $('#noteComp').hide();
    $('#noteSlice').show();
    $('#btnMostrar').show();
    $('#btnOcultar').hide();
  }

  blurSave(e){
    let note = e.target.innerText;
    this.notas = note;
    this._cotizacionService.updateNote(this.cotizacion.number, note).subscribe((data: any) => {
        console.log('Data :', data);
        this._cotizacionService.setCotizacion(data);
        this.verMenos();
      }, (err) => {
        console.error('Error :', err);
      });
  }
}
