import { User } from './user';
import { Roll } from './roll';
import { Access } from './access';

export class Admin extends User {
	rol?: Roll;
	accesos?: Access[];

	constructor() {
		super();
	}
}