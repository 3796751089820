export class Catalogos{

    constructor(){

    }

    /**
     * @description Obtener nombre de la aerolineas
     * @param {Object[]} airlines - Lista de aerolíneas
     * @param {(Object|string)} ruta - Objeto de la ruta o string del código de la aerolínea
     * @return {string} Nombre de aerolínea
     */
    getAirlineName(airlines, ruta) {
        //Detectar si se escribe el codigo de aerolínea o toda la ruta
        if (typeof ruta === 'string') {
            let airport = airlines.filter(x => x.code === ruta);
            if (airport.length > 0) {
                return airport[0].name;
            }
            return ruta;
        } else {
            let airport = airlines.filter(x => x.code === ruta.subRoutes[0].airline)
            if (airport.length > 0) {
                return airport[0].name;
            }
            return ruta.subRoutes[0].airline;
        }
    }


    /**
     * @description Obtener nombre de ciudad, país y/o aeropuerto
     * @param {Object[]} airports - Lista de aeropuertos
     * @param {string} iata - Código iata 
     * @param {string} type - Tipo de retorno: 'airport', 'city', 'country', 'region', 'full'
     * @return {string} Nombre solicitado en type
     */
    getNameByIATA(airports, iata, type) {
        let name = airports.filter(x => x.iata === iata);
        let selectedName = "";
        switch (type) {
            case 'airport':
                selectedName = name[0].name;
                break;
            case 'city':
                selectedName = name[0].city.name;
                break;
            case 'country':
                selectedName = name[0].name.country.name;
                break;
            case 'region':
                selectedName = name[0].name.country.region.name;
                break;
            case 'full':
                selectedName = `${name[0].city.name}, ${name[0].city.country.name}`;
                break;
        }
        return selectedName;
    }


    /**
     * @description Obtener logo de la aerolínea
     * @param {(Object|string)} ruta - Objeto de la ruta o string del código de la aerolínea
     * @returns {string} - URL de aerolínea
     */
    getAirlineLogo(ruta) {
        if((typeof ruta === "undefined" || ruta === null)){
            return "https://goprivate.wspan.com/sharedservices/images/airlineimages/logoAir00.gif";
        }else{
            //Detectar si se escribe el codigo de aerolínea o toda la ruta
            if (typeof ruta === 'string') {
                return `https://goprivate.wspan.com/sharedservices/images/airlineimages/logoAir${ruta}.gif`;
            } else {
                return `https://goprivate.wspan.com/sharedservices/images/airlineimages/logoAir${ruta.subRoutes[0].airline}.gif`;
            }
        }
    }


    /**
     * @description Obtener tipo de pasajero
     * @param {string} code - Código de tipo de pasajero
     * @returns {string} - Descripción de tipo de pasajero
     */
    getPassengerType(code) {
        switch(code){
            
            case 'CNN':
                return 'Niño';
            case 'INF':
                return 'Infante';
            case 'ADT':
            default:
                return 'Adulto';
        }
    }
}