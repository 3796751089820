import { Subject } from 'rxjs/Subject';

export class Loader {

  private Loader = new Subject<boolean>();
	public Loader$ = this.Loader.asObservable();
  
  /**
	 * [action] 
	 * @param {boolean} statusLoader [description]
	 */
	public action(statusLoader: boolean) {
		this.Loader.next(statusLoader);
	}

}
