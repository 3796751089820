import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  private currentStep:any;

  constructor(private _router:Router) { 
  }

  ngOnInit() {

  }

  stepNumber(){
    this.currentStep = this._router.url.split('step-');
    return Number(this.currentStep[1]);
  }

}
