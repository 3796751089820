import { Component, OnInit } from '@angular/core';
import { DetallesService } from '../../../../shared/services/detalles.service';
import { UtilitiesAppService } from '../../../../shared/services/utilities-app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detalle-busqueda',
  templateUrl: './detalle-busqueda.component.html',
  styleUrls: ['./detalle-busqueda.component.scss', '../../../../../assets/fuv/css/style-rightbar-details.css']
})
export class DetalleBusquedaComponent implements OnInit {
  // private submitted = false;
  public cotizacion: any;
  isCollapsed = true;

  constructor(
    private _detallesService: DetallesService,
    private _util: UtilitiesAppService,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.getDataForm();
  }

  getDataForm() {
    this._detallesService.getDataForm().subscribe((data: any) => {
      this.cotizacion = Object.assign(Object.create(Object.getPrototypeOf(data)), data);
      // console.log('datos de la cotización seleccionada: ', this.cotizacion);
    });
  }

  searchQuote() {
    // this.submitted = true;
    // console.log('valor del formulario', this.cotizacion.number);

    this._util.cotizacion.searchQuote(this.cotizacion.number);
  }

  rightBarClose() {
    document.getElementById('notificationSidebar').style.display = 'none';
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;

    if (!this.isCollapsed) {
      $('.servicedrop').addClass('fa-chevron-down').removeClass('fa-chevron-right');
    } else {
      $('.servicedrop').addClass('fa-chevron-right').removeClass('fa-chevron-down');
    }
  }

  irCotizacion() {
    localStorage.setItem('cotizacion-cliente', JSON.stringify(this.cotizacion));
    this._router.navigate(['/cotizacion/step-1']);
  }
}
