import * as moment from 'moment';
export class DateUtility {
    constructor() {

    }

    getDaysBetween2Dates(date1, date2, format) {
        // NO CALCULA BIEN ARREGLAR
        var a = moment(date1, format);
        var b = moment(date2, format);
        return Math.abs(a.diff(b, 'days'));
    }

    ngbDateParser(date, format) {
        return moment([date.year, date.month, date.day]).format(format)=='Invalid date'?moment([date.year, date.month-1, date.day]).format(format):moment([date.year, date.month, date.day]).format(format);
    }


    /**
     * @description Calcula los días restantes para la salida de un vuelo
     * @param ruta - Ruta seleccionada para viajar
     */
    getDatetoFlight(ruta){
        // let miRuta = ruta;
        // let d = miRuta[miRuta.length-1].subRoutes[miRuta[miRuta.length-1].subRoutes.length-1];
        // d = d.departureDate?d.departureDate:d.arrivalDate;
        let d = ruta.route.arrivalDate?ruta.route.arrivalDate:ruta.route.departureDate;
        let md = moment(d);
        // console.log("GETDATE:", d, md, md.diff(moment(), 'days'));
        return md.diff(moment(), 'days');
      }

    getMinDate(value, ruta, type) {
        var minDate = moment();
        let dateFlight = this.getDatetoFlight(ruta);
        // console.log("MINDATE: ", value, dateFlight)
        switch (value) {
          case 'CNN':
            minDate = moment().subtract(12, 'y').add('days',dateFlight+2);
            break;
          case 'INF':
            minDate = moment().subtract(2, 'y').add('days', dateFlight+2);
            break;
          default:
            minDate = moment([1920, 1, 1]);
            break;
        }

        switch(type){
            case 'utc':
                let md = minDate.utc()
                let returnDate = {
                    year: Number(md.year()),
                    month: Number(md.month()+1),
                    day: Number(md.date())
                    }
                return returnDate;
            break;
            default:
            return minDate;
        }
      }
    
      getMaxDate(value, type) {
        var maxDate = moment();
        // console.log("MAX:", value)
        switch (value) {
          case 'CNN':
            maxDate = moment().subtract(2, 'y').subtract(1,'d');
            break;
          case 'INF':
            maxDate = moment().subtract(1,'d');
            break;
          default:
            maxDate = moment().subtract(12, 'y');
            break;
        }
        switch(type){
            case 'utc':
                let md = maxDate.utc()
                let returnDate = {
                    year: Number(md.year()),
                    month: Number(md.month()+1),
                    day: Number(md.date())
                    }
                return returnDate;
            break;
            default:
            return maxDate;
        }
      }
    
}