import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'filterHotel' })
export class HotelPipe implements PipeTransform {
    transform(value, filter): any {
        let res = JSON.parse(JSON.stringify(value));
        console.log("Filtrando:", value);
        if (filter != undefined && filter != null) {
            if (filter.nuStars.length > 0) {
                res = res.filter(x => filter.nuStars.includes(x.nuStars));
            }
            if (filter.zones.length > 0) {
                res = res.filter(x => filter.zones.includes(x.txZone));
            }

            if (filter.name.length > 0) {
                res = res.filter(x => new RegExp(filter.name, 'gi').test(x.txName))
            }

            if (filter.regimens.length > 0 || filter.price.length > 0) {
                res = res.map(hotel => {
                    if (filter.price.length > 0) {
                        let currencyValue = 1;
                        if (hotel.codeCurrency == "USD") {
                            currencyValue = filter.currency.USDExchangeRate;
                        }
                        if (hotel.codeCurrency == "EUR") {
                            currencyValue = filter.currency.EURExchangeRate;
                        }
                        hotel.rooms = hotel.rooms.filter(x => filter.price.includes(parseFloat(x.price) * currencyValue))
                    }
                    if (filter.regimens.length > 0) {
                        hotel.rooms = hotel.rooms.filter(x => filter.regimens.includes(x.roomCharacteristicCode));
                    }
                    return hotel;
                });

                res = res.filter(hotel => hotel.rooms.length > 0);
            }
        }
        return res;
    }
}