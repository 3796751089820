import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFromIso'
})
export class DateFromIsoPipe implements PipeTransform {

  transform(value: any, type: any, format: any, lang:any): any {
    // console.log("PIPE: ", value, "Tipo:", type)
    // console.log(value.substr(0, value.length - 9));
    let defaultDateFormat = 'DD-MM-YYYY';
    let defaultHourFormat = 'hh:mm a';
    // We ignore the TimeZone
    moment.locale('es');
    let valueNoUTC = value.includes('+0000')?value.substr(0, value.length - 9):value.substr(0, value.length - 7);
    let dateObject = moment(valueNoUTC);
    if(value.length==10){
      valueNoUTC = new Date(value.split('-').reverse());
      valueNoUTC = valueNoUTC.toISOString(); 
      dateObject = moment(valueNoUTC);
    }
    // console.log(value);
    //console.log(moment().toISOString());

    if (type === 'date'){
      let temporal = format ? dateObject.format(format) : dateObject.format(defaultDateFormat);
      return temporal[0].toUpperCase() + temporal.substr(1);
    }
    else if (type === 'hour'){
      return format ? dateObject.format(format) : dateObject.format(defaultHourFormat);
    } else if (type === 'slash'){
      return format ? dateObject.format('DD/MM/YYYY') : dateObject.format(defaultHourFormat);
    }
    return value;
  }

}
