import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { HandlerService } from './../handlers/handlers.service';
import { environment as params } from './../../../environments/environment';
import { LoaderService } from "../../core/loader/loader.service";

@Injectable()
export class MethodsService {

  public api: string;

  constructor(
    public _HandlerService: HandlerService,
    private http: HttpClient,
    private _loader: LoaderService
  ) {
    this.api = (<any>params).api.host;
  }



  /**
  * Performs any type of get Token.
  * @param none
  * @param none
  * @returns {token}
  */


  public getToken(): string {
    return localStorage.getItem('TOKEN');
  }

  /**
  * Performs any type of http request.
  * @param urlService
  * @param query
  * @returns {Response}
  */

  get(urlService: string, query: any, showLoader: boolean = true) {

    let getUrl;

    if (query) {
      getUrl = `${urlService}?filter=${query}`;
    } else {
      getUrl = urlService;
    }
    // try {
    return this.http.get(this.api + getUrl)
      .map((data: any) => {
        if (data.status != undefined && data.status != null && !data.status) {
          throw Observable.throw(data);
        }
        return data;
      })
      .catch(this._HandlerService.error);
    // } catch (data) {
    //   console.log("error", data);
    // }
  }

  /**
  * Performs any type of http request.
  * @param urlService
  * @param id
  * @param query
  * @returns {Response}
  */

  findOne(urlService: string, id: string, query: any) {

    let getUrl;

    if (query) {
      getUrl = `${urlService}/${id}/?filter=${query}`;
    } else {
      getUrl = `${urlService}/${id}`;
    }

    return this.http.get(this.api + getUrl)
      .map((data: any) => {
        if (data.status != undefined && data.status != null && !data.status) {
          throw Observable.throw(data);
        }
        return data;
      })
      .catch(this._HandlerService.error);
  }


  /**
  * Performs any type of http request.
  * @param urlService
  * @param data
  * @returns {Response}
  */

  post(urlService: string, data: any) {
    return this.http.post(this.api + urlService, data)
      .map((data: any) => {
        if (data.status != undefined && data.status != null && !data.status) {
          throw Observable.throw(data);
        }
        return data;
      })
      .catch(this._HandlerService.error);
  }

  /**
  * Performs any type of http request.
  * @param urlService
  * @param id
  * @param data
  * @returns {Response}
  */

  putMethod(urlService: string, data: any) {
    return this.http.put(this.api + `${urlService}`, data)
      .map((data: any) => {
        if (data.status != undefined && data.status != null && !data.status) {
          throw Observable.throw(data);
        }
        return data;
      })
      .catch(this._HandlerService.error);
  }

  /**
  * Performs any type of http request.
  * @param urlService
  * @param id
  * @param data
  * @returns {Response}
  */

  patch(urlService: string, id: string, data: any) {
    return this.http.patch(this.api + `${urlService}/${id}`, data)
      .map((data: any) => {
        if (data.status != undefined && data.status != null && !data.status) {
          throw Observable.throw(data);
        }
        return data;
      })
      .catch(this._HandlerService.error);
  }

  /**
  * Performs any type of http request.
  * @param urlService
  * @param id
  * @returns {Response}
  */


  delete(urlService: string, id: string) {
    return this.http.delete(this.api + `${urlService}/${id}`)
      .map((data: any) => {
        if (data.status != undefined && data.status != null && !data.status) {
          throw Observable.throw(data);
        }
        return data;
      })
      .catch(this._HandlerService.error);
  }

}

