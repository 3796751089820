import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { TitleComponent } from './title/title.component';
// Angular material
import {
  MatSidenavModule,
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatExpansionModule,
  MatButtonModule,
  MatListModule,
  MatCardModule,
  MatDividerModule,
  MatInputModule,
} from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';


// Components
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RightBarComponent } from './right-bar/right-bar.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { DetallesComponent } from '../pages/full-layout-page/seguimientos/detalles/detalles.component';
import { PagsComponent } from './pags/pags.component';
import { DetallesComponent as DetallesClientesComponent } from '../pages/full-layout-page/clientes/detalles/detalles.component';
import { DetalleComponent as DetalleUsuarioComponent } from '../pages/full-layout-page/usuarios/detalle/detalle.component';
import { DetalleBusquedaComponent } from '../pages/full-layout-page/busqueda/detalle-busqueda/detalle-busqueda.component';
import { ErrorsControlComponent } from './components/errors-control/errors-control.component';
import { LoadingComponent } from './components/loading/loading.component';
// import { MaterializeModule } from 'angular2-materialize';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    // MatSidenavModule,
    // MatToolbarModule,
    // MatMenuModule,
    // MatIconModule,
    // MatExpansionModule,
    // MatButtonModule,
    FlexLayoutModule,
    // MaterializeModule,
    NgbModule,
    MalihuScrollbarModule.forRoot(),
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    TitleComponent,
    RightBarComponent,
    PagsComponent,
    NotificationsComponent,
    DetallesComponent,
    DetallesClientesComponent,
    ErrorsControlComponent,
    LoadingComponent,
    DetalleUsuarioComponent,
    ErrorsControlComponent,
    DetalleBusquedaComponent
  ],
  exports: [
    RouterModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FooterComponent,
    NavbarComponent,
    TranslateModule,
    SidebarComponent,
    // MaterializeModule,

    /* angular/material */
    MatIconModule,
    MatExpansionModule,
    MatButtonModule,
    FlexLayoutModule,
    MatListModule,
    MatCardModule,
    MatDividerModule,
    MatInputModule,
    NgbModule,
    TitleComponent,
    RightBarComponent,
    PagsComponent,
    ErrorsControlComponent,
    LoadingComponent
    /* **************** */
  ]
})
export class TemplateModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: TemplateModule
    };
  }
}
