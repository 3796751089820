import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'currencyExchange' })
export class CurrencyExchangePipe implements PipeTransform {
    transform(value, from, to, intelisis = null): any {
        if (typeof value == "string") {
            value = parseFloat(value);
        }
        try {
            if (from != "MXN" && from != "EUR" && from != "USD") {
                return this.numberWithCommas(value.toFixed(2)) + " " + from;
            }

            let currencyValue = 1;
            if (from == "USD") {
                currencyValue = intelisis.USDExchangeRate;
            }
            if (from == "EUR") {
                currencyValue = intelisis.EURExchangeRate;
            }

            let res = value * currencyValue; // Pesos
            let exchangeValue = null;
            switch (to) {
                case 'MXN':
                    exchangeValue = 1;
                    break;
                case 'USD':
                    exchangeValue = intelisis.USDExchangeRate;
                    break;
                case 'EUR':
                    exchangeValue = intelisis.EURExchangeRate;
                    break;
            }
            res = res / exchangeValue;
            return this.numberWithCommas(res.toFixed(2)) + " " + to;
        } catch (error) {
            return this.numberWithCommas(value.toFixed(2)) + " " + from;
        }
    }
    numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
}