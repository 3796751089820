import { Injectable } from '@angular/core'
import { MethodsService } from "../method.service";
import { BehaviorSubject } from 'rxjs';
import { reject } from 'q';

@Injectable()
export class ClientesService {

  private listSubject = new BehaviorSubject([]);
  private list = [];

  constructor(
    private _service: MethodsService
  ) {

  }

  getlist() {
    return this.listSubject.asObservable();
  }

  resetList() {
    this.list = [];
    this.refresh();
  }

  saveClient(client) {
    return this._service.post("client", client)
      .map((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  }

  getAddressByZipCode(zipCode) {
    return this._service.get('intelisis/address/' + zipCode, '')
    .map((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });;
  }

  getRegimen() {
    return this._service.get('intelisis/regimenFiscal','')
    .map((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });;
  }

  updateList(filter: string = "") {
    if (filter === "") {
      return;
    }

    this._service.get('client/' + filter, '').subscribe((data) => {
      this.list = data.data;
      this.refresh();
    }, (err) => {
      this.list = [];
      this.refresh();
    });
  }

  updateClient(client) {
    return this._service.putMethod("client", client)
      .map((data) => {
        return data.data;
      })
      .catch((err) => {
        return err;
      });
  }

  updateClientInlist(client) {
    let index;
    index = this.list.findIndex((item) => item.account_id === client.account_id);
    if (index >= 0) {
      this.list[index] = client;
      this.refresh();
    }
  }

  private refresh() {
    this.listSubject.next(this.list);
  }

}

