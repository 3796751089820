import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'usuariosPipe'
})
export class UsuariosPipe implements PipeTransform {

    transform(items: any, filter: any): any {
        // console.log('VALOR DE LA BUSQUEDA: ', filter);
        // console.log('VALOR DE ITEMS: ', items);
        const exp = new RegExp(filter, 'i');
        return items.filter(item => exp.test(item.firstName) || exp.test(item.lastName) || exp.test(item.mothersName)
            || exp.test(item.mail) || exp.test(item.username) || exp.test(item.idIntelisis));
    }
}
