import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { LoaderState } from './loader';

@Injectable()

export class LoaderService {

  private loaderSubject = new Subject<LoaderState>();

  loaderState = this.loaderSubject.asObservable();
  loaderEnable = true;
  constructor() { }

  disableLoader(status: boolean) {
    this.loaderEnable = status;
  }

  showPreloader() {
    if ( this.loaderEnable ){
      this.loaderSubject.next(<LoaderState>{ show: true });
    }
  }

  hidePreloader() {
    this.loaderSubject.next(<LoaderState>{ show: false });
  }

}
