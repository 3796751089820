import { Component, OnInit, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../_auth/auth.service';
import { User } from '../../core/_models/user';
import { NotificationsComponent } from "../notifications/notifications.component";
import { RightBarService } from "../../shared/services/right-bar-service";
import { Socket1Service } from 'src/app/shared/services/utilities/sockets/socket1.service';
import { Socket2Service } from 'src/app/shared/services/utilities/sockets/socket2.service';
import { Socket3Service } from 'src/app/shared/services/utilities/sockets/socket3.service';

declare let $: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
  user: any;
  username:any;
  sessionTime: any = { minutos: 10, segundos: 2 };
  scrollbarOptions = { axis: 'y', theme: 'minimal-dark' };
  constructor(
    private translate: TranslateService,
    private _auth: AuthService,
    private _zone: NgZone,
    private _rightBarService: RightBarService,
    private _socket1: Socket1Service,
    private _socket2: Socket2Service,
    private _socket3: Socket3Service
  ) {
    this.user = this._auth.getUser();
    this.username = this.user.USER_NAME?this.user.USER_NAME:'Username';
  }

  ngOnInit() {
  }

  logout() {
    this._auth.deleteUser();
  }

  public ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  toggleSidebar() {
    $('#sidebar, #content, .overlay').toggleClass('active');
  }

  notification_open() {
    this._rightBarService.addDynamicComponent(NotificationsComponent, "Notificaciones");
  }

}
