import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../core/_models/user';

// import { AuthService } from '../../_auth/auth.service';
// import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DashboardService } from '../../shared/http/full-layout-page/dashboard.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';

declare let $: any;

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	@Input() user: User;
	@Output() logout = new EventEmitter<boolean>();
	public page = 'dashboard';
	public formSearch: FormGroup;

	constructor(
		// private _auth: AuthService,
		// private translate: TranslateService,
		private _router: Router,
		private _dashboardService: DashboardService,
		private fb: FormBuilder
	) { }

	ngOnInit() {
		this.formSearch = this.fb.group({
			search: ['']
		});
	}

	/* Set the width of the sidebar to 250px and the left margin of the page content to 250px */
	openNav() {
		if ($('#sidebar').hasClass('toggled')) {
			// $('#sidebar').addClass('animated slideInLeft')
			document.getElementById('sidebar').style.width = '250px';
			document.getElementById('content-wrapper').style.marginLeft = '250px';
		} else {
			// $('#sidebar').addClass('animated slideOutLeft')
			document.getElementById('sidebar').style.width = '0px';
			document.getElementById('content-wrapper').style.marginLeft = '0';
		}
		$('#sidebar').toggleClass('toggled');
	}
	/* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
	closeNav() {
		var element = document.getElementById('sidebar');
		element.classList.add('toggled');
		document.getElementById('sidebar').style.width = '0px';
		document.getElementById('content-wrapper').style.marginLeft = '0';
	}

	// Open the full screen search box 
	openSearch() {
		document.getElementById('myOverlay').style.display = 'block';
	};
	// Close the full screen search box 
	closeSearch() {
		document.getElementById('myOverlay').style.display = 'none';
	};

	getUrl() {
		// console.log(this._router.url);
		return this._router.url;
	}

	search() {
		this._dashboardService.searh(this.formSearch.controls.search.value).subscribe((resp: any) => {
			// console.log('RESPUESTA DE LA BUSQUEDA: ', JSON.stringify(resp));
			if (isNullOrUndefined(resp) === false || resp !== '') {
				localStorage.setItem('respBusqueda', JSON.stringify(resp));

				this._router.navigate(['/busqueda']);

				this.closeSearch();
			}
		});
	}

}
