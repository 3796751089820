import * as moment from 'moment';

export class HotelModel {
    hotel = {
        fareData: {
            aditionalFee: 0,
            baseFare: 0,
            commission: 0,
            commissionPercentage: 0,
            currency: {
                type: ""
            },
            equivFare: 0,
            price: 0,
            providerPrice: 0,
            revenue: 0,
            serviceCharge: 0,
            tax: 0,
            total: 0
        },
        hotelDetail: {
            nightCount: 0,
            roomCount: 0
        },
        itinerary: {

        },
        passengerDataList: [],
        productType: {
            description: "Hoteles",
            typeProduct: "Hotel"
        },
        route: {
            arrivalDate: "",
            arrivalDateString: "",
            arrivalDescription: "", // mismo de departureDescription 
            arrivalIATA: "", // code
            departureDate: "",
            departureDateString: "",
            departureDescription: "", // webstring del typehead
            departureIATA: "", //code
        },
    };

    constructor(
        cotizacion = null,
        selectedProduct = null
    ) {
        if (cotizacion != null && selectedProduct != null) {
            let bodyRequest = JSON.parse(localStorage.getItem('hotel/step1/requestBody'));
            //fareData
            this.hotel.fareData.baseFare = parseFloat(selectedProduct.txPrice);
            this.hotel.fareData.currency.type = selectedProduct.codeCurrency;
            this.hotel.fareData.total = parseFloat(selectedProduct.txPrice);

            //hotelDeatil
            this.hotel.hotelDetail.roomCount = selectedProduct.rooms[0].roomCount;
            this.hotel.hotelDetail.nightCount = this.getDaysBetween2Dates(selectedProduct.dateTo, selectedProduct.dateFrom, "YYYYMMDD");
            // Itinerary
            this.hotel.itinerary = selectedProduct;

            // route
            this.hotel.route.arrivalDate = this.getDate(bodyRequest.segments[0].arrivalDate, "DD/MM/YYYY");
            this.hotel.route.arrivalDescription = bodyRequest.arrivalTmp.webString;
            this.hotel.route.arrivalIATA = bodyRequest.arrivalTmp.code;
            this.hotel.route.departureDate = this.getDate(bodyRequest.segments[0].departureDate, "DD/MM/YYYY");
            this.hotel.route.departureDescription = this.hotel.route.arrivalDescription;
            this.hotel.route.departureIATA = bodyRequest.arrivalTmp.code;

            // passengerDataList
            let types = this.getPassagerTypes(selectedProduct);
            types.forEach(type => {
                this.hotel.passengerDataList.push(this.fillPassager(selectedProduct, type));
            });

            console.log("Cotizacion hospedajes step2", cotizacion);
            console.log("producto a agregar", selectedProduct);

        }
    }

    getPassagerTypes(selectedProduct) {
        let types = [];
        for (let index = 0; index < selectedProduct.rooms[0].adultOcupancy; index++) {
            types.push("AD");
        }
        for (let index = 0; index < selectedProduct.rooms[0].childOcupancy; index++) {
            types.push("CH");
        }
        return types;
    }

    fillPassager(selectedProduct, passangerType) {
        let price = parseFloat(selectedProduct.rooms[0].price) / (selectedProduct.rooms[0].adultOcupancy + selectedProduct.rooms[0].childOcupancy);
        return {
            age: 0,
            birthday: "",
            fareData: {
                aditionalFee: 0,
                baseFare: price,
                commission: 0,
                commissionPercentage: 0,
                currency: {
                    type: selectedProduct.codeCurrency
                },
                equivFare: 0,
                price: price, 
                providerPrice: 0,
                revenue: 0,
                serviceCharge: 0,
                tax: 0,
                total: price 
            },
            gender: "",
            id: "",
            lastName: "",
            mail: "",
            mothersName: "",
            name: "",
            passengerCode: {
                accountCode: "",
                promo: false,
                realType: passangerType, 
                type: passangerType
            },
            passengerContact: {
                gender: "",
                lastName: "",
                mail: "",
                mothersName: "",
                name: "",
                phone: "",
                type: ""
            },
            phone: ""
        }
    }

    getDaysBetween2Dates(date1, date2, format) {
        // NO CALCULA BIEN ARREGLAR
        var a = moment(date1, format);
        var b = moment(date2, format);
        return Math.abs(a.diff(b, 'days'));
    }

    getDate(date, format) {
        return moment(date, format).format("YYYY-MM-DD") + 'T00:00:00.000Z';
    }
}