import { Component, OnInit } from '@angular/core';
import { DetallesService } from '../../../../shared/services/detalles.service';
import { NgForm } from '@angular/forms';
import { UsuariosService } from '../../../../shared/http/full-layout-page/usuarios.service';
import { UtilitiesAppService } from '../../../../shared/services/utilities-app.service';
import * as moment from 'moment';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss', '../../../../../assets/fuv/css/style-rightbar-details.css']
})
export class DetalleComponent implements OnInit {
  public submitted = false;
  public edit = true;
  public usuario: any;
  public sucursales = [];
  public lstRoles = [];

  constructor(
    private _detallesService: DetallesService,
    private _usuariosService: UsuariosService,
    private _util: UtilitiesAppService
  ) { }

  ngOnInit() {
    this._usuariosService.getSucursales().subscribe((resp: any) => {
      this.sucursales = resp;
    });

    this._usuariosService.getRoles().subscribe((resp: any) => {
      this.lstRoles = resp;
      // console.log('VALORES DE LA LISTA DE ROLES: ', this.lstRoles);
    });

    this.getDataForm();
  }

  getDataForm() {
    this._detallesService.getDataForm().subscribe((data: any) => {
      this.usuario = Object.assign(Object.create(Object.getPrototypeOf(data)), data);
      // console.log('INFORMACIÓN DEL USUARIO SELECCIONADO: ', this.usuario);
    }, (err) => {
      console.error('ERROR EN EL SERVICIO GETDATAFORM: ', err);
    });
  }

  onSubmit(f: NgForm) {
    this.submitted = true;
    // console.log('INFORMACION DEL FORMULARIO: ', f);
    if (f.valid) {
      // console.log('INFORMACION DEL USUARIO: ', this.usuario);
      this._usuariosService.updateUsuario(this.usuario).subscribe((data) => {
        this._usuariosService.updateUsuarioInlist(data.data);
        this.rightBarClose();
        this._util.toastr.info('Usuario actualizado exitosamente', '¡Éxito!', {
          timeOut: 4000,
          closeButton: true
        });
      }, (error) => {
        this._util.toastr.success(error.message ? error.message : 'Error en el servidor', 'Error', {
          timeOut: 4000,
          closeButton: true
        });
      });
    } else {
      console.log('invalido');
      alert('El formulario no es valido (selecciona un perfil)');
      // console.log(f.controls);
    }
  }

  toggleEdit() {
    this.edit = !this.edit;
  }

  rightBarClose() {
    document.getElementById('notificationSidebar').style.display = 'none';
  }

  validateAge(event) {
    const age = this.usuario.birthday;
    const fechanacimiento = moment(age, 'DD/MM/YYYY');
    // console.log(fechanacimiento, age.length)
    if (event.inputType !== 'deleteContentBackward') {
      switch (age.length) {
        case 2:
        case 5:
          this.usuario.birthday = age + '/';
          break;
      }
    }

    if (!fechanacimiento.isValid() && age.length === 10) {
      this.usuario.birthday = '';
    }

    const years = moment().diff(fechanacimiento, 'years');
    if (years < 18 && age.length === 10) {
      this.usuario.birthday = '';
    }
  }
}
