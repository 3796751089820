import { ComponentFactoryResolver, Injectable, Inject, ReflectiveInjector } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class RightBarService {
    factoryResolver: any;
    rootViewContainer: any;
    private titleSubject = new BehaviorSubject([]);
    private title;
    private direccionSubject = new BehaviorSubject([]);
    private direccion;
    private exitSubject = new BehaviorSubject([]);
    private exit;
    private overlaySubject = new BehaviorSubject(false);
    private overlay: boolean = false;

    constructor(
        @Inject(ComponentFactoryResolver) factoryResolver,
        private _router: Router
    ) {
        this.factoryResolver = factoryResolver;
    }

    getTitle() {
        return this.titleSubject.asObservable();
    }

    getDireccion() {
        return this.direccionSubject.asObservable();
    }

    getStatuaBar() {
        return this.exitSubject.asObservable();
    }

    getOverlay() {
        return this.overlaySubject.asObservable();
    }

    closeRightBar() {
        this.exit = true;
        this.overlay = false;
        this.exitSubject.next(this.exit);
        this.overlaySubject.next(this.overlay);
    }

    openRightBar(overlay = false) {
        this.overlay = overlay;
        document.getElementById("notificationSidebar").style.display = "block";
        this.exit = false;
        this.exitSubject.next(this.exit);
        this.overlaySubject.next(this.overlay);
    }

    private refresh() {
        this.titleSubject.next(this.title);
        this.direccionSubject.next(this.direccion);
        this.overlaySubject.next(this.overlay);
    }

    setRootViewContainerRef(viewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    }

    addDynamicComponent(componentClass, title = '', direccion = "arriba", overlay = false) {
        if (this.exit !== true) {
            this.closeRightBar();
        }
        this.direccion = direccion;
        this.title = title;
        this.refresh();
        this.removeComponent();
        const factory = this.factoryResolver.resolveComponentFactory(componentClass);
        const component = factory.create(this.rootViewContainer.parentInjector);
        this.rootViewContainer.insert(component.hostView);
        setTimeout(() => {
            this.openRightBar(overlay);
        }, 100);
        return this._router.url;
    }

    removeComponent() {
        this.rootViewContainer.remove(0);
    }
}