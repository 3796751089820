import { Component, OnInit, Input } from '@angular/core';
import * as $ from "jquery";
import { Router } from '@angular/router';
import { CotizacionService } from 'src/app/shared/http/full-layout-page/cotizacion/cotizacion.service';


@Component({
  selector: 'app-right-menu',
  templateUrl: './right-menu.component.html',
  styleUrls: ['./right-menu.component.scss']
})
export class RightMenuComponent implements OnInit {
  client: any;
  @Input() proceso: boolean = true;
  @Input() collapse: boolean = true;
  @Input() order = null;
  cotizacion;
  constructor(
    private _router: Router,
    private _cotizacionService: CotizacionService
  ) {
    this.client = JSON.parse(localStorage.getItem("cotizacion-cliente"));
    if (!this.client) {
      this._router.navigate(['/clientes']);
    }
    this.getCotizacion();
  }

  ngOnInit() {
    console.log(this.client);

  }

  getCotizacion() {
    this._cotizacionService.getCotizacion().subscribe(data => {
      this.cotizacion = data;
    });
  }

  verMas() {
    $('#masDatos').slideDown();
    $('#verMas').hide();
    $('#verMenos').show();
  }

  verMenos() {
    $('#masDatos').slideUp();
    $('#verMas').show();
    $('#verMenos').hide();
  }

}

