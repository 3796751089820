import { Injectable, Input } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router, CanActivate, CanDeactivate } from '@angular/router';
import { Subject } from 'rxjs';

import { Admin } from '../core/_models/admin';
import { Access } from '../core/_models/access';
import { environment } from '../../environments/environment';
import { log } from 'util';


@Injectable()
export class AuthService implements CanActivate {
  private ROUTES_ACCESS = environment.ROUTES_ACCESS;
	constructor(private _router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// Redirijir si no esta logeado
		
		if ( !this.isLogged() ) {
			this._router.navigate(['']);
			return false;
		} 
		
		if ( this.getRouteAccess(state.url).length < 1 ) {
			return true;
		}
		return true;
		if ( this.checkAccess(this.getRouteAccess(state.url)[0].id).length > 0 ) {
			return true;
		} else {
			this.deleteUser();
			return false;
		}
	}

	isLogged(): boolean {
		let token = this.getToken();
		return token !== undefined && token !== null;
	}

	getUser(): any {
		let user = new Admin();
		if ( localStorage.getItem('bl-currentUser') ) {
			user = JSON.parse(localStorage.getItem('bl-currentUser'));
		}
		return user;
	}

	setUser(user: any): void { // user, token
		localStorage.setItem('bl-currentUser', JSON.stringify(user));
	}

	getTokenPrefix() {
		if ( localStorage.getItem('bl-currentUser') ) {
			return JSON.parse(localStorage.getItem('bl-currentUser')).TOKEN_PREFIX;
		}
		return 'Bearer ';
	}

	getAccess(): Access[] {
		let access = [];
		if ( localStorage.getItem('bl-currentUser') ) {
			access = JSON.parse(localStorage.getItem('bl-currentUser')).user.accesos;
		}
		return access;
	}

	checkAccess(accessId: number) {
		let pageAccess = this.ROUTES_ACCESS.filter(data => data.id === accessId)[0].access;
		let userRoles = this.getUser().USER_ROLE;
		return userRoles.filter((role) => pageAccess.includes(role.authority));
	}

	getToken(): string {
		let token = undefined;
		if ( localStorage.getItem('bl-currentUser') ) {
			token = this.getTokenPrefix() + JSON.parse(localStorage.getItem('bl-currentUser')).TOKEN;
		}
		return token;
	}

	getTokenTime(): Date {
		let time = null;
		if ( localStorage.getItem('bl-currentUser') ) {
			time = JSON.parse(localStorage.getItem('bl-currentUser')).EXPIRATION_TIME;
		}
		return new Date(time);
	}

	getRouteAccess(url: string, read?: boolean): any[] {
		return this.ROUTES_ACCESS.filter( (x) => x.routes.filter( (y) => url.indexOf(y) > -1 ).length > 0 );
	}

	deleteUser(): void {
		localStorage.removeItem('bl-currentUser');
		this._router.navigate(['']);
	}

}