import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DateUtility } from "./utilities/date";

/*** include jQuery **************/
import * as $ from 'jquery';
import { Catalogos } from './utilities/catalogos';
import { Cotizacion } from './utilities/cotizacion';
import { CotizacionService } from '../http/full-layout-page/cotizacion/cotizacion.service';
import { Router } from '@angular/router';
/********************************/


@Injectable()
export class UtilitiesAppService {
  private confirmButtonText: string = '';
  private cancelButtonText: string = '';
  public toastr: any;
  public catalogos: Catalogos = new Catalogos();
  public date: DateUtility = new DateUtility();

  public cotizacion: Cotizacion;
  constructor(
    private translate: TranslateService,
    private _cotizacionService: CotizacionService,
    private _router: Router,
    private _toastr: ToastrService
  ) {
    this.cotizacion = new Cotizacion(this._cotizacionService, this._router);
    this.toastr = _toastr;
    this.translate.get('buttons.accept').subscribe((res: string) => {
      this.confirmButtonText = res;
    });
    this.translate.get('buttons.cancel').subscribe((res: string) => {
      this.cancelButtonText = res;
    });
  }

  /**
   * [_pasteValid] valida que no se copie y peguen caracteres especiales en un
   * cuadro de texto (input text)
   * @param {any} val [valor en el input text cuando pierde el foco (on blur)]
   */
  public pasteValid(val: any) {
    let target = val.target.value;
    let thisID = val.target.id;

    if (target) {
      let pattPaste = /[`~!$%^&*|\=?;'"<>\{\}\[\]]/gi;
      if (target.match(pattPaste)) {
        this.sweetAl("No se permiten caracteres especiales");
        val.target.value = "";
      }
    }
  }
  /**
   * [handledErrorbyServices description]
   * @param {any}       error [description]
   * @param {string =     ''}          title [description]
   */
  public handledErrorbyServices(error: any, title: string = "") {
    if (error && error.status >= 400) {
      let objErr = JSON.parse(error._body);
      this.sweetAl(objErr.error.message, "error", title);
    }
  }

  /**
   * [isPasswCorrect] valida que el password cumpla con las reglas de negocio
   * @param {string} pass password a validad
   */
  public isPasswCorrect(event: any) {
    let pattern = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]{3})(?=.*[?!@#\$\(\)\{\}=\/\.\,\;\:\*])).{8,12}$/;
    return pattern.test(event);
  }

  /**
   * [noCopyPaste] impide se active la función de pegar en el input
   * @param {string} idEle identificador del elemento
   */
  public noCopyPaste(idEle: string) {
    $("#" + idEle).bind("paste copy", function (e) {
      e.preventDefault();
    });
  }
  /**
   * [keyValid description]
   * @param {any} event [description]
   */
  public keyValid(event: any, space = false) {
    let pattern;

    if (space == true) {
      pattern = /^[\w\_@.\-\\\a-zA-ZáéíóúAÉÍÓÚÑñ0-9(:),#]+$/;
    } else {
      pattern = /^[\w\_@.\-\\\a-zA-ZáéíóúAÉÍÓÚÑñ0-9(:),#\s]+$/;
    }

    let inputChar = String.fromCharCode(event.charCode);

    if (space == true && event.charCode == 32) {
      event.preventDefault();
    } else if (!pattern.test(inputChar) && event.charCode != 0) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  /**
   * [isEmail] Verifica si el formato de email es el correcto
   * @param {event} event evento que se emite al escribir el email
   */
  public isEmail(event: any) {
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(event);
  }

  /**
   * [keyDisabled] deshabilita teclas de navegación
   * @param {any} event [event keypress]
   * @param {array} range [rango o numero de teclas a deshabilitar]
   */
  public keyDisabled(event: any, range: any) {
    if (event.keyCode >= range[0] && event.keyCode <= range[1]) {
      event.preventDefault();
    }
  }

  /**
   * [isArray description]
   * @param {[type]} object [description]
   */
  public isArray(object: any) {
    if (object.constructor === Array) return true;
    else return false;
  }

  /**
   * [stringContainNumber]
   * @param value
   * return {boolean }  false: onlyNumbers || true: alphabethic
   */
  public validateTypeContain(value) {
    let pattern = /^\d+$/
    return !value.match(pattern)
  }

  /**
   * [isEmpty] Revisa que un objeto se encuentre vacio
   * @param {object} obj [objeto a evaluar]
   * @return devuelve un boolean true si esta vacio false si tiene elementos
   */
  public isEmpty(obj) {
    for (var i in obj) {
      return false;
    }
    return true;
  }

  /**
   * [showPercent] Evalua un valor y devuelve el porcentaje del mismo con
   * respecto al total
   * @param {[integer]} item  [valor del cual se desea obtener su porcentaje]
   * @param {[integer]} total [valor contra el cual se obtendrá el porcentaje]
   * @return {integer}
   */
  public showPercent(item, total) {
    let porcent = item / total * 100;
    if (isNaN(porcent)) {
      return 0;
    }
    return porcent.toFixed(2);
  }

  /**
   * [_closefade]  elimnar el container backoff  del mensaje de sweetAlert, sólo aplica en IE >9
   * @param {[function jQuery]} ) { $('.swal2-container').remove
   */
  public _closefade() {
    $("html, body").removeClass("swal2-shown");
    $(".swal2-container").remove();
  }

  /**
   * [sweetAl] Configuración básica para sweetAlert
   * @param  {string}       message [description]
   * @param  {string       =       'warning'}   typeAlert  [tipo de alerta | success | error | info]
   * @param  {string    =       ''}          titleAlert [titulo que debe aparecer al mostrar un Alerta]
   * @return {Promise<any>}         [ejecuta una funcion al cerrar la Alerta]
   */
  public sweetAl(message: string, typeAlert: any = "warning", titleAlert: string = ""): Promise<any> {
    let theMessage;

    if (message.length == 0) {
      theMessage = "Error";
    } else {
      theMessage = message;
    }

    let objSweetAl = {
      title: titleAlert,
      text: theMessage,
      type: typeAlert,
      width: "350px",
      confirmButtonColor: "#ec961f",
      confirmButtonText: this.confirmButtonText,
      allowOutsideClick: false,
      allowEscapeKey: false
    };

    return new Promise((resolve, reject) => {
      resolve(
        swal(objSweetAl).then(() => {
          this._closefade();
        })
      );
    });
  }

  /**
   * [sweetAlConfirm] Confirm Alert sweetAlert
   * @param  {string} message [description]
   * @param  {string = 'warning'}   typeAlert  [tipo de alerta | success | error | info]
   * @param  {string = ''}          titleAlert [titulo que debe aparecer al mostrar un Alerta]
   * @return {Promise<any>}         [ejecuta una funcion al cerrar la Alerta]
   */
  public sweetAlConfirm(
    message: string,
    typeAlert: any = "warning",
    titleAlert: string = "",
    html: any = "",
    confirmButtonText: string = "Si",
    cancelButtonText: string = "No",
    allowOutsideClick: boolean = false,
    allowEscapeKey: boolean = false
  ): Promise<any> {
    let theMessage;

    if (message.length == 0) {
      theMessage = "Error";
    } else {
      theMessage = message;
    }

    let objSweetAl = {
      title: titleAlert,
      text: theMessage,
      type: typeAlert,
      width: "350px",
      allowOutsideClick: allowOutsideClick,
      allowEscapeKey: allowEscapeKey,
      showCancelButton: true,
      confirmButtonClass: 'success',
      cancelButtonClass: 'danger',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      html: html
    };


    return new Promise((resolve, reject) => {
      swal(objSweetAl).then((data) => {
        this._closefade();
        if (!data.dismiss) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  }

  /**
   * [sweetAl] TextArea
   */
  public sweetAlTextArea(
    message: string, typeAlert: any = "", titleAlert: string = "", input: any = "", inputPlaceholder: any = ""
  ): Promise<any> {

    let objSweetAl = {
      title: titleAlert,
      text: message,
      type: typeAlert,
      input: input,
      inputPlaceholder: inputPlaceholder,
      showCancelButton: true,
      width: "450px",
      confirmButtonColor: "#ec961f",
      confirmButtonText: this.confirmButtonText,
      cancelButtonText: this.cancelButtonText,
      allowOutsideClick: false,
      allowEscapeKey: false
    };

    return new Promise((resolve, reject) => {
      resolve(
        swal(objSweetAl).then((data) => {
          // this._closefade();
          return data;
        })
      );
    });
  }

  /**
   * [isIE] Verifica el si el tipo de navegador que se está usando es IE
   * @return {boolean} [TRUE Si el navegador que se está usando es IE ]
   */
  public isIE(): boolean {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (
      navigator.appName == "Microsoft Internet Explorer" ||
      msie > 0 ||
      !!navigator.userAgent.match(/Trident.*rv\:11\./)
    ) {
      return true; //alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
    } else {
      return false;
    } // If another browser, return 0
  }

  /**
   * [shorten description]
   * @param {[type]} text      [description]
   * @param {[type]} maxLength [description]
   */
  public shorten(text, maxLength) {
    var ret = text;
    if (ret && ret.length > maxLength) {
      ret = ret.substr(0, maxLength - 3) + "...";
    }
    return ret;
  }


  /**
   * [removePropertyEmpty]
   * @param obj
   */
  public removePropertyEmpty(obj) {
    Object.keys(obj).forEach(key =>
      (obj[key] && typeof obj[key] === 'object') && this.removePropertyEmpty(obj[key]) ||
      (obj[key] === undefined || obj[key] === null) && delete obj[key]
    );
    return obj;
  };

  /**
   * [formatDate]
   * @param date
   */
  public formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  /**
   * [generateQueryString]
   * Esta guapa función se encarga de convertir un objeto en un lindo
   * query string para ser enviado en una petición.
   * @param queryObj
   */
  public generateQueryString(queryObj: Object): string {
    let queryStr: string = '?';
    Object.keys(queryObj).forEach(function (k) {
      if (queryObj[k] != '' || typeof (queryObj[k]) == 'boolean') {
        queryStr += `${k}=${queryObj[k]}&`;
      }
    });
    return queryStr.slice(0, -1);
  }
} // end class
