import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'errors-control',
  templateUrl: './errors-control.component.html',
  styleUrls: ['./errors-control.component.scss']
})
export class ErrorsControlComponent implements OnChanges {
  @Input() errors: Array<any> = [];
  errorList: any = {
    required: "Campo requerido"
  };
  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  getError(errorKey) {
    return this.errorList[errorKey];
  }
}
