/**Core */
import { Component, OnInit, Injectable, isDevMode } from "@angular/core";

/** constants */
import { environment } from "../../environments/environment";

@Component({
  selector: "app-core",
  template: ""
})
@Injectable()
export class CoreComponent {
  private token: string;
  private pathSession: any;
  private errorMessage: string;

  constructor() {}

  public init() {
    if (environment.production && !isDevMode()) {
      sessionStorage.clear();
    }
  }
}

export function pathFactory(_core: CoreComponent) {
  return () => _core.init();
}
