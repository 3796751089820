import { Injectable } from '@angular/core';
import { MethodsService } from "../../method.service";
import { HotelModel } from './models/hoteles.model';

@Injectable({
  providedIn: 'root'
})
export class HotelesService {

  constructor(
    private _service: MethodsService
  ) { }

  getDestinos() {
    return this._service.get("hotel/destinations", "");
  }
  /**
   * @description Obtener lista de categorias de hoteles
   * @returns Arreglo de objetos de aerolíneas
   * @UsedIn  PaquetesNac/step-1
   */
  getCategoryHotels() {
    return this._service.get('hotelsCategory', '');
  }
  /**
   * @description Obtener tipos de habitaciones de hoteles
   * @returns Arreglo de objetos de tipos de habitación
   * @UsedIn  PaquetesNac/step-1
   */
  getTypesHotelsRoom() {
    return this._service.get('typeHotelsRoom', '');
  }

  addProductSelectedHotel(cotization, selectedProduct) {
    let body = new HotelModel(cotization, selectedProduct).hotel;
    return this._service.post("quote/addProductSelectedHotel/" + cotization.number, body)
      .map((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  }

  updateHotel(body, quote) {
    return this._service.putMethod("quote/updateHotel/" + quote, body);
  }

  emitirHotel(numQuute, indexProduct) {
    let body = {
      generateArticleIntelisis: true,
      indexProduct: indexProduct,
      numberQuote: numQuute
    }
    console.log("emitir hotel body: ", body);
    return this._service.post("issueProduct/emitirHotel", body);
  }

  reservar(body) {
    return this._service.post("hotel/reservation", body);
  }

}
