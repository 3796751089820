/** Core */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { TemplateModule } from '../template/template.module';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipe } from './_pipes/filter.pipe';
import { SeguimientosPipe } from './_pipes/seguimientos.pipe';
import { DateFromIsoPipe } from './_pipes/date-from-iso.pipe';
import { WizardComponent } from '../pages/full-layout-page/cotizacion/componentes/wizard/wizard.component';
import { MinutesToHoursPipe } from './_pipes/minutes-to-hours.pipe';

import { KeysPipe } from './_pipes/keys.pipe';
import { RightMenuComponent } from '../pages/full-layout-page/cotizacion/componentes/right-menu/right-menu.component';
import { VuelosFilterPipe } from './_pipes/vuelos-pipe.pipe';
import { PromocionesComponent } from '../pages/full-layout-page/cotizacion/componentes/promociones/promociones.component';
import { MisNotasComponent } from '../pages/full-layout-page/cotizacion/componentes/mis-notas/mis-notas.component';
import { SegCotEliComponent } from '../pages/full-layout-page/cotizacion/componentes/seg-cot-eli/seg-cot-eli.component';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DisableControlDirective } from './directives/disable-control.directive';
import { HotelPipe } from './_pipes/hoteles-pipe.pipe';
import { FilterArticlesPipe } from './_pipes/filter-articles.pipe';
import { CurrencyExchangePipe } from "./_pipes/currency.pipe";
import { UsuariosPipe } from './_pipes/usuarios.pipe';

@NgModule({
  exports: [
    TranslateModule,
    FilterPipe,
    VuelosFilterPipe,
    SeguimientosPipe,
    DateFromIsoPipe,
    WizardComponent,
    MinutesToHoursPipe,
    RightMenuComponent,
    PromocionesComponent,
    MisNotasComponent,
    KeysPipe,
    CurrencyExchangePipe,
    NgbModule,
    DisableControlDirective,
    HotelPipe,
    FilterArticlesPipe,
    UsuariosPipe
  ],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    TemplateModule
  ],
  declarations: [
    FilterPipe,
    VuelosFilterPipe,
    SeguimientosPipe,
    DateFromIsoPipe,
    CurrencyExchangePipe,
    WizardComponent,
    MinutesToHoursPipe,
    RightMenuComponent,
    KeysPipe,
    PromocionesComponent,
    MisNotasComponent,
    DisableControlDirective,
    HotelPipe,
    FilterArticlesPipe,
    UsuariosPipe
  ]
})
export class SharedModule { }
