import { Component, OnInit, NgZone } from '@angular/core';
import { User } from '../../core/_models/user';
import { AuthService } from '../../_auth/auth.service';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';

@Component({
	selector: 'app-full-layout',
	templateUrl: './full-layout.component.html',
	styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {
	user: User;
	count: number;
	repeat: number = 0;
	sessionTime: any = { minutes: 15, seconds: 0 };
	source = { mouse: Observable.fromEvent(document, 'mousemove'), key: Observable.fromEvent(document, 'keypress') };

	constructor(private _auth: AuthService, private _zone: NgZone, private _router: Router) {
		this.user = this._auth.getUser();
		_router.events.subscribe((val) => {
			if (this._auth.isLogged()) {
				this.resetCount();
				this.countBack();
			}
		});

		this.source.mouse.subscribe((e) => {
			if (this._auth.isLogged()) {
				this.resetCount();
			}
		});

		this.source.key.subscribe((e) => {
			if (this._auth.isLogged()) {
				this.resetCount();
			}
		});
	}

	ngOnInit() {
		if (this._auth.isLogged()) {
			this.resetCount();
			this.countBack();
		}
	}

	logout() {
		this._auth.deleteUser();
	}

	countBack(): void {
		let counter = () => {
			this.count--;
			if (this.count < 0) {
				return this._auth.deleteUser();
			}
			this.sessionTime = { minutes: ((Math.floor(this.count / 60)) % 60), seconds: (this.count % 60) };
		}
		if (this.repeat < 1) {
			let timer = setInterval(counter, 1000);
		}
		this.repeat++;
	}

	resetCount(): void {
		this.count = 601;
	}
}
