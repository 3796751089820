export const environment = {
  production: false,
  api: {
    host: 'http://10.20.30.106:8020/fuv_core_api/'
  },
  ROUTES_ACCESS: [
    {
      id: 1,
      access: ['ROLE_ADMIN', 'ROLE_USER'],
      name: 'Mi Dashboard',
      routes: ['/dashboard']
    },
    {
      id: 2,
      access: ['ROLE_ADMIN', 'ROLE_USER'],
      name: 'Clientes',
      routes: ['/clientes']
    },
    {
      id: 3,
      access: ['ROLE_ADMIN', 'ROLE_USER'],
      name: 'Cotizaciones',
      routes: ['/seguimientos']
    },
    {
      id: 4,
      access: ['ROLE_ADMIN', 'ROLE_USER'],
      name: 'Cotizacion',
      routes: ['/cotizacion']
    }
  ]
};
