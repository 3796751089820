import { CotizacionService } from "../../http/full-layout-page/cotizacion/cotizacion.service";
import { Router } from "@angular/router";

export class Cotizacion{
    constructor(
        private _cotizacionService: CotizacionService,
        private _router: Router
    ){

    }

    /**
     * @description Obtiene el producto seleccionado de la lista de productos
     * @param cotizacion - Objeto JSON cotizacion
     * @param index - Indice del producto seleccionado
     * @param typeProduct - Tipo de producto seleccionado
     * @returns Regresa el objeto producto el cuál fue seleccionado en el paso 2
     */
    getCurrentProduct(cotizacion, index, typeProduct){
        let products = cotizacion.productList;
        let selectedProduct = products.filter(product => product.index==index && product.productType.typeProduct==typeProduct.code);      
        // console.log("LOG:", products, selectedProduct, index, typeProduct);
        
        return selectedProduct.length>0?selectedProduct[0]:[];
    }

    /**
     * @description Guarda en localStorage el indice del producto añadido
     * @param cotizacion - Objeto JSON cotizacion
     */
    saveProductSelected(cotizacion){
        localStorage.setItem('productSelected', cotizacion.selectedProduct.index);
    }
    

    searchQuote(number){
        console.log("Search 2:", number);
        this._cotizacionService.getCotizationByNumber(number).subscribe((data: any) => {
            console.log('Data :', data);
            let currentUser = localStorage.getItem('bl-currentUser');
            localStorage.clear(); //Limpiar rastros de cotización
            localStorage.setItem("cotizacion-cliente", JSON.stringify(data.client));
            localStorage.setItem("cotizacion", JSON.stringify(data));
            localStorage.setItem('bl-currentUser', currentUser);
            
            switch(Number(data.step)){
                case 3:

                    let typeProduct = null;
                    if ((typeof data.selectedProduct !== "undefined" || data.selectedProduct !== null)) {
                        typeProduct = data.selectedProduct.productType.typeProduct;
                    }else{
                        if ((typeof data.selectedProductList !== "undefined" || data.selectedProductList !== null)) {
                            typeProduct = data.selectedProductList[0].productType.typeProduct;
                        }
                    }

                    switch(typeProduct){
                        case 'Air':
                            this._router.navigate(['/cotizacion/vuelos/step-3']) 
                        break;
                        case 'Hotel':
                            this._router.navigate(['/cotizacion/hoteles/step-3']) 
                        break;
                        case 'Insurance':
                            this._router.navigate(['/cotizacion/seguros/step-3']) 
                        break;
                        case 'InternationalPackage':
                            this._router.navigate(['/cotizacion/paquete-int/step-3']) 
                        break;
                        case 'NationalPackage':
                            this._router.navigate(['/cotizacion/paquete-nac/step-3']) 
                        break;
                        case 'Membership':
                            this._router.navigate(['/cotizacion/membresias/step-3']) 
                        break;
                        case 'Tour':
                            this._router.navigate(['/cotizacion/tours/step-3']) 
                        break;
                        case 'TravelArticle':
                            this._router.navigate(['/cotizacion/articulos/step-3']) 
                        break;
                        case 'GenericHotel':
                            this._router.navigate(['/cotizacion/otros-hoteles/step-3']) 
                        break;
                    }

                break;
                case 4:
                 this._router.navigate(['/cotizacion/step-4'])
                break;
                case 5:
                    this._router.navigate(['/cotizacion/step-5'])
                break;
                case 6:
                    this._router.navigate(['/cotizacion/step-6'])
                break;
                default:
                    this._router.navigate(['/cotizacion/step-1'])
                break;
            }

            }, (err) => {
            console.error('Error :', err);
        });

    }
}