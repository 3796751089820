import { Injectable } from '@angular/core';
import { MethodsService } from '../../method.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(
    private _service: MethodsService
  ) {

  }

  getActionList() {
    return this._service.get("actionTracking/list", "");
  }

  getQualificationList() {
    return this._service.get("actionTrackingQualification/list", "");
  }

  addTracking(numberQuote, body) {
    return this._service.post("tracing/addTracking/" + numberQuote, body);
  }

  updateTracking(numberQuote, body) {
    return this._service.post("tracing/updateTracking/" + numberQuote, body);
  }

}
