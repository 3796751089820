import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterArticles',
    pure: false
})
export class FilterArticlesPipe implements PipeTransform {

    transform(value: any, filter: any): any {
        /*console.log('VALUE: ', value);
        console.log('FILTER: ', filter)*/

        if (!value || !filter) {
            return value;
        }
        // Visa filters, IF empty or false shows all the itineraries
        const newElementsArticle = [];

        value.forEach(function (article) {
            if (filter === article.family) {
                newElementsArticle.push(article);
            }
        });

        return newElementsArticle;
    }
}
