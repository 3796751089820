import { Component, OnInit } from '@angular/core';
import { DetallesService } from "../../../../shared/services/detalles.service";
import { NgForm } from '@angular/forms';
import { ClientesService } from "../../../../shared/http/full-layout-page/clientes.service";
import { UtilitiesAppService } from "../../../../shared/services/utilities-app.service";
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detalles',
  templateUrl: './detalles.component.html',
  styleUrls: ['./detalles.component.scss', '../../../../../assets/fuv/css/style-rightbar-details.css']
})
export class DetallesComponent implements OnInit {
  submitted: boolean = false;
  client: any;
  edit = true;
  isCollapsed = true;
  sameDataCheck = false;
  colonias: any;
  addressEnable = false;
  regimen: any = [
    { code: 601, name: "Regimen default" }
  ];
  constructor(
    private _detallesService: DetallesService,
    private _clientesService: ClientesService,
    private _util: UtilitiesAppService,
    private _router: Router,
  ) {
    this.getDataForm();
    this.getRegimen();
  }

  ngOnInit() {

  }

  getRegimen(){
    this._clientesService.getRegimen().subscribe((data:any) => {
      console.log("Regimen:", data);
      // data = mock;
      this.regimen = data.data;
    }, (error) => {
      console.log("DATAS ERROR:", error);
    });
  }

  getDataForm() {
    this._detallesService.getDataForm().subscribe((data: any) => {
      this.client = Object.assign(Object.create(Object.getPrototypeOf(data)), data);
      console.log(this.client);
      this.client.birthday = moment(this.client.birthday, "YYYY-MM-DD").format('DD/MM/YYYY');
    }, (err) => {

    });
  }

  onSubmit(f: NgForm) {
    this.submitted = true;
    console.log(f);
    if (f.valid) {
      this.client.birthday =  moment(this.client.birthday, "DD/MM/YYYY").format('YYYY-MM-DD');
      this._clientesService.updateClient(this.client).subscribe((data) => {
        this._clientesService.updateClientInlist(data);
        this.rightBarClose();
        this._util.toastr.info('Cliente actualizado exitosamente', '¡Éxito!', {
          timeOut: 4000,
          closeButton: true
        });
      }, (error) => {
        this._util.toastr.success(error.message ? error.message : 'Error en el servidor', 'Error', {
          timeOut: 4000,
          closeButton: true
        });
      });
    } else {
      console.log("invalido");
      console.log(f.controls);
    }
  }

  toggleEdit() {
    this.edit = !this.edit;
  }

  rightBarClose() {
    document.getElementById("notificationSidebar").style.display = "none";
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;

    if (!this.isCollapsed) {
      $(".servicedrop").addClass('fa-chevron-down').removeClass('fa-chevron-right');
    } else {
      $(".servicedrop").addClass('fa-chevron-right').removeClass('fa-chevron-down');
    }
  }

  validateAge(event) {
    let age = this.client.birthday;
    var fechanacimiento = moment(age, "DD/MM/YYYY");
    // console.log(fechanacimiento, age.length)
    if (event.inputType !== "deleteContentBackward") {
      switch (age.length) {
        case 2:
        case 5:
          this.client.birthday = age + "/";
          break;
      }
    }

    if (!fechanacimiento.isValid() && age.length == 10) {
      this.client.birthday = '';
    }

    var years = moment().diff(fechanacimiento, 'years');
    if (years < 18 && age.length == 10) {
      this.client.birthday = '';
    }
  }

  sameData() {
    console.log(this.sameDataCheck)
    let check = this.sameDataCheck;

    if (check) {
      let fullname = this.client.firstname + " " + this.client.lastname + " " + this.client.mothersname;
      this.client.fullname = fullname;
      this.client.mailingphone = this.client.mobile;
      this.client.mailingemail = this.client.email;
    } else {
      this.client.fullname = '';
      this.client.mailingemail = '';
      this.client.mailingphone = '';
    }
  }

  getAddress() {
    let zipCode = this.client.mailingzip;
    if (zipCode.length == 5) {
      console.log("Servicio");
      this._clientesService.getAddressByZipCode(zipCode).subscribe((data: any) => {
        // data = mock;
        this.setAddress(data.data);
      }, (error) => {
        console.log("DATAS ERROR:", error);
      });
    }
  }

  setAddress(data) {

    console.log("Colonias:", data);
    this.colonias = data.map(c => { return { name: c.coloniaDescripcion } });
    this.client.mailingstate = data[0].estadoDescripcion;
    this.client.mailingcity = data[0].municipioDescripcion;

    this.addressEnable = true;

  }

  cotizacion() {
    let currentUser = localStorage.getItem('bl-currentUser');
    localStorage.clear(); //Limpiar rastros de cotización
    localStorage.setItem("cotizacion-cliente", JSON.stringify(this.client));
    localStorage.setItem('bl-currentUser', currentUser);
    this._router.navigate(['/cotizacion/step-1']);
  }
}
