/**core */
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/**Material style */
// import { MaterializeModule } from 'angular2-materialize';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

/*** routing */
import { AppRoutingModule } from './app-routing.module';

/**HTTP */
import { HttpModule, Http, XHRBackend, RequestOptions } from '@angular/http';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

/**Basic components */
import { AppComponent } from './app.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { TemplateModule } from './template/template.module';

/***external dependences */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from './_auth/auth.service';
import { MethodsService } from './shared/http/method.service';
import { CoreHttpInterceptor } from './core/http.interceptor';

// Loader
import { LoaderService } from './core/loader/loader.service';
import { LoaderComponent } from './core/loader/loader.component';
import { Loader } from './shared/services/loader';
import { UtilitiesAppService } from './shared/services/utilities-app.service'
import { SharedModule } from './shared/shared.module';
import { CoreComponent } from './core/core';
import { HandlerService } from './shared/handlers/handlers.service';
import { RightBarService } from './shared/services/right-bar-service';
import { DetallesService } from './shared/services/detalles.service';
import { ClientesService } from './shared/http/full-layout-page/clientes.service';
import { SeguimientosService } from './shared/http/full-layout-page/seguimientos.service';
import { UsuariosService } from './shared/http/full-layout-page/usuarios.service';
import { NotificationsComponent } from './template/notifications/notifications.component';
import { DetallesComponent } from './pages/full-layout-page/seguimientos/detalles/detalles.component';
import { DetallesComponent as DetallesClientesComponent } from './pages/full-layout-page/clientes/detalles/detalles.component';
import { DetalleComponent as DetalleUsuarioComponent } from './pages/full-layout-page/usuarios/detalle/detalle.component';
import { DetalleBusquedaComponent } from './pages/full-layout-page/busqueda/detalle-busqueda/detalle-busqueda.component';
import { CookieService } from 'ngx-cookie-service';

/***functions */
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/* Alerts */
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './shared/date-parser-formatter';

import { HotelesService } from './shared/http/full-layout-page/cotizacion/hoteles.service';
import { FacturarService } from './shared/http/full-layout-page/cotizacion/facturar.service';
import { TrackingService } from "./shared/http/full-layout-page/cotizacion/tracking.service";

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    LoaderComponent,
    CoreComponent,
  ],
  imports: [
    BrowserModule,
    SnotifyModule,
    ToastrModule.forRoot(),
    TemplateModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule, // Enable animations
    // NoopAnimationsModule, // Disable animations
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    Loader,
    AuthService,
    LoaderService,
    CoreComponent,
    CookieService,
    HandlerService,
    UtilitiesAppService,
    TrackingService,
    HotelesService,
    MethodsService,
    RightBarService,
    FacturarService,
    DetallesService,
    ClientesService,
    SeguimientosService,
    UsuariosService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    {
      provide: 'SnotifyToastConfig',
      useValue: ToastDefaults
    }, {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter
    },
    SnotifyService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NotificationsComponent,
    DetallesComponent,
    DetallesClientesComponent,
    DetalleUsuarioComponent,
    DetalleBusquedaComponent
  ]
})
export class AppModule { }

