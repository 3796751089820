import { Injectable } from '@angular/core';
import { MethodsService } from '../method.service';

@Injectable()
export class UsuariosService {
    private list = [];

    constructor(
        private _service: MethodsService
    ) { }

    getUsuarios() {
        return this._service.get('usuario', '')
            .map((data) => {
                return data.data;
            })
            .catch((err) => {
                return err;
            });
    }

    updateUsuario(usuario) {
        return this._service.putMethod('usuario/' + usuario.id, usuario)
            .map((data) => {
                return data;
            })
            .catch((err) => {
                return err;
            });
    }

    saveUsuario(usuario) {
        return this._service.post('usuario', usuario)
            .map((data) => {
                return data;
            })
            .catch((err) => {
                return err;
            });
    }

    getRoles() {
        return this._service.get('profiles', '')
            .map((data) => {
                return data.data;
            }).catch((err) => {
                return err;
            });
    }

    getSucursales() {
        return this._service.get('branch/list', '')
            .map((data) => {
                return data.data;
            }).catch((err) => {
                return err;
            });
    }

    updateUsuarioInlist(client) {
        let index;
        index = this.list.findIndex((item) => item.id === client.id);
        if (index >= 0) {
            this.list[index] = client;
        }
    }

    llenadoLista(info) {
        this.list = info;
    }
}
