import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class HandlerService {

  public response;

  constructor() {

  }

  error(error: any) {
    return Observable.throw(error);
  }

  success(response: any) {
    return response;
  }


}
