import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { LoaderService } from './loader/loader.service';
import { Observable } from 'rxjs/Rx';
import { ActivatedRoute, Router } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";
import { UtilitiesAppService } from "../shared/services/utilities-app.service";
import { AuthService } from '../_auth/auth.service';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';


@Injectable()
export class CoreHttpInterceptor implements HttpInterceptor {

    private loaderService;
    private pendingRequests: number = 0;

    constructor(private router: Router,
        private injector: Injector,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private utilities: UtilitiesAppService,
        private _auth: AuthService) {
        this.loaderService = this.injector.get(LoaderService);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authReq = (this._auth.getToken()) ?
            req.clone({
                headers: req.headers.set('Authorization', this._auth.getToken())
            }) : req.clone();
        this.pendingRequests++;
        //  console.log(req.body)
        this.turnOnRequest();

        return next
            .handle(authReq)
            .do(response => {
                if (response instanceof HttpResponse) {
                    this.turnOffRequest();
                }
            }, error => {
                this.turnOffRequest();

                switch (Number(error.status)) {
                    case 401:
                        this.router.navigate([''], { relativeTo: this.route.parent });
                        break;

                    case 409: break;

                    default:
                        const msgError: string = `catalog_error.${error.message}`;

                        this.translate
                            .get([msgError, 'catalog_error.generic_error'])
                            .subscribe((res: any) => {
                                if (res[msgError].match(/./)) {
                                    const ge: string = res['catalog_error.generic_error'];

                                    this.utilities
                                        .sweetAl(ge, 'warning', '')
                                        .then(null)
                                } else {
                                    this.utilities
                                        .sweetAl(res[msgError], 'error')
                                        .then(null)
                                }
                            });
                }

            });
    }

    private turnOnRequest() {
        this.loaderService.showPreloader();
    }

    private turnOffRequest() {
        this.pendingRequests--;

        if (this.pendingRequests <= 0) {
            this.loaderService.hidePreloader();
        }
    }
}
