import { Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';


@Injectable({
  providedIn: 'root'
})
export class Socket2Service {

  private serverUrl = 'http://10.20.30.106:8022/fuv_core_seguimiento/seguimiento'
  private title = 'WebSockets chat';
  private stompClient;

  constructor() { 
    this.initializeWebSocketConnection();
  }

  initializeWebSocketConnection(){
    console.log("Inicializado Socket 2")
    let ws = new SockJS(this.serverUrl);
    this.stompClient = Stomp.over(ws);
    this.stompClient.debug = null //Esta línea quita los console.log de STOMP.JS
    let that = this;
    this.stompClient.connect({}, function(frame) {
      that.stompClient.subscribe("/topic/seguimiento", (message) => {
        if(message.body) {
          console.log("Mensaje 2:", message.body);
        }
      });
    });
  }

  sendMessage(message){
    this.stompClient.send("/app/intelisis_advances" , {}, message);
  }
}
